@import 'colors';
@import 'fontfamily';

.btn {
  text-align: center;
  justify-content: center;
  @include letter-spacing(0.4px);
  @include font-size(12px);
  line-height: 20px;
  text-transform: uppercase;
  font-family: $font-family-Acceptance-Bold;
  box-sizing: border-box;
  text-decoration: none;

  &.btn-primary,
  &.btn-secondary,
  &.btn-tertiary {
    background-color: $yellow;
    color: $primary-black;
    border: 1px solid rgba(255,255,255,0);
    padding: 0.5rem 1.5rem;
    &:hover , &:focus {
      background-color: $primary-yellow;
    }
  }

  &.btn-pill {
    border: 2px solid $indigo;
    border-radius: 19px;
    background-color: $white;
    color: $indigo;
    padding: 0.3rem 2rem;
    &:hover , &:focus {
      border: 3px solid $secondary-indigo;
      color: $secondary-indigo;
    }
  }

  &.btn-light {
    font-family: $font-family-Acceptance-DemiBold !important;
    &:focus {
      outline: none;
      border: none;
      background-color: none;
    }
    &:focus-visible {
      color: inherit;
      background-color: inherit;
      border-color: inherit;
      outline: none;
      box-shadow: none;
    }
  }

  &.btn-load-more{
    border: 3px solid #0D5D73;
    border-radius: 5px;
    background-color: #FCFCFC;
    padding: 0.3rem 2rem;
    color: #0D5D73;
  }

}
