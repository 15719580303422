@import 'colors';
@import 'typography';

.tab-title {
    color: $primary-indigo;
    font-family: $font-family-Acceptance-Bold;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0.6rem 0;
}

.tab-content {
    box-shadow: 0 0 60px rgba(0,0,0,0.3);
}

.active-tab{
    background-color: #FFF;
}

.inactive-tab{
    background-color: #E8E8E0;
}