@import 'typography';
@import 'colors';
@import 'fontfamily';

nav {
  ul {
    counter-reset: item;
    li {
      a {
        text-transform: uppercase;
        @include font-weight(600);
        @include font-size(12px);
        letter-spacing: 0.7px;
        line-height: 22px;
        font-family: $font-family-Acceptance-DemiBold;
        color: $primary-black;
        text-decoration: none;
        padding: 8px 16px;
        &:hover {
          color: $primary-black;
        }
      }
      &.decimal-list {
        display: flex;
        align-items: flex-start;
        list-style: none;
        counter-increment: item;
        &:first-child::before {
          padding: 3px 10px;
        }
        &:not(:first-child)::before {
          padding: 3px 8px;

        }
        &::before {
          content: counter(item, decimal) "";
          border-radius: 50%;
          background-color: $indigo;
          color: #F4F4EC;
          font-family: $font-family-Visby-Medium;
          font-size: 14px;
          line-height: 17px;
          margin-top: 0.25rem;
        }
      }
      &.upper-alpha {
        display: flex;
        align-items: flex-start;
        list-style: none;
        counter-increment: item;
        &::before {
          content: counter(item, upper-alpha) "";
          border-radius: 50%;
          background-color: $indigo;
          color: #F4F4EC;
          font-family: $font-family-Visby-Medium;
          font-size: 14px;
          line-height: 17px;
          padding: 2px 4px;
        }
      }
    }
  }
}

.side-nav-links {
  color: $indigo;
  font-family: $font-family-Acceptance-Bold;
  letter-spacing: 0.4px;
  font-size: 10px;
}

.header-main-links,
.header-main-links:hover {
  text-transform: uppercase;
  @include font-weight(600);
  @include font-size(12px);
  letter-spacing: 0.7px;
  line-height: 20px;
  font-family: $font-family-Acceptance-DemiBold;
  color: $primary-black;
  overflow: visible; 
}

.indigo-link {
  color: $indigo;
  font-family: $font-family-Acceptance-Regular;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  &:hover {
    color: $secondary-indigo;
    text-decoration: underline;
  }
}

.sitemap-links {
  color: $black;
  font-family: $font-family-Visby-Regular;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  text-decoration: none;
  text-transform: capitalize;
  padding: 0;
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}

.footer-links {
  color: $tertiary-indigo;
  font-family: $font-family-Visby-Medium;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  padding: 0;
  &:hover {
    color: $tertiary-indigo;
    text-decoration: underline;
  }
}

.quick-links { 
  box-sizing: border-box;
  border: 2px solid $secondary-brown;
  border-radius: 5px;
  text-align: center;
  width: 10rem;
  height: 62px;
  &:hover {
    border: 2px solid $violet;
    background-color: $indigo;
    color: $default-white;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.skip-link {
  position: absolute;
  top: -43px;
  left: 0;
  z-index: 100;
  background-color: $default-white;
  border: 1px solid $violet;
  padding: 8px;
  text-decoration: none;
  color: $black;
  transition: all .2s ease-in-out;
}

.skip-link:focus {
  top: 0;
}