.bb-dotted-black {
  border-bottom: 1px dotted $black;
}

.bl-dotted-yellow {
  border-left: 5px dotted $yellow;
}

.br-dotted-yellow {
  border-left: 5px dotted $yellow;
}

.bb-dotted-yellow {
  border-bottom: 5px dotted $yellow;
}

.bb-dotted-orange {
  border-bottom: 5px dotted $orange;
}

.bb-dotted-indigo {
  border-bottom: 5px dotted $indigo;
}

.bb-dotted-secondary-brown {
  border-bottom: 2px dotted $secondary-brown;
}

.bt-dotted-indigo {
  border-top: 5px dotted $indigo;
}

@include media-breakpoint-up(md) {
  .br-solid {
    border-right: 1px solid $black;
  }  
}