$white: #FCFCFC;
$primary-white: #FCFCFA;
$tertiary-white: #F8F9FA;
$secondary-white: #D8D8D8;
$default-white: #FFFFFF;
$orange: #FE5F00;
$primary-orange: #FA6400;
$secondary-orange: #F34213;
$tertiary-orange: #CC5100;
$brown: #A6A6A6;
$secondary-brown: #E8E8E0;
$black: #1B1B1B;
$primary-black: #171714;
$violet: #14A1C8;
$yellow: #FFB800;
$primary-yellow: #CF9400;
$indigo: #2B4361;
$btn-indigo: #21364F;
$primary-indigo: #1A2933;
$secondary-indigo: #0A293B;
$tertiary-indigo: #3D5470;
$green: #8FCC1F;
$light-blue: rgba(232, 247, 252, 0.5);
$sky-blue: #0F7D9C;
$yankees-blue: #1D2D3D;

$colors: (
  credit-white: $white,
  primary-white: $primary-white,
  tertiary-white: $tertiary-white,
  secondary-white: $secondary-white,
  default-white: $default-white,
  credit-orange: $orange,
  primary-orange: $primary-orange,
  secondary-orange: $secondary-orange,
  tertiary-orange: $tertiary-orange,
  credit-brown: $brown,
  secondary-brown: $secondary-brown,
  credit-indigo: $indigo,
  credit-black: $black,
  primary-black: $primary-black,
  credit-violet: $violet,
  credit-yellow: $yellow,
  primary-yellow: $primary-yellow,
  btn-indigo: $btn-indigo,
  primary-indigo: $primary-indigo,
  secondary-indigo: $secondary-indigo,
  tertiary-indigo: $tertiary-indigo,
  yankees-blue: $yankees-blue,
  credit-green: $green,
  light-blue: $light-blue,
  sky-blue: $sky-blue
);

@mixin bgc-colors($colors) {
  @each $name, $color in $colors {
    .bgc-#{$name} {
      background-color: $color;
    }
  }
}

@mixin text-colors($colors) {
  @each $name, $color in $colors {
    .text-#{name} {
      color: $color
    } 
  }
}


@include bgc-colors($colors);
@include text-colors($colors);
