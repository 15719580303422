@import 'bootstrap/scss/bootstrap';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'bootstrap/scss/mixins';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'app/styles/utilities';
@import 'app/styles/links';
@import 'app/styles/colors';
@import 'app/styles/cards';
@import 'app/styles/heading';
@import 'app/styles/buttons';
@import 'app/styles/borders';
@import 'app/styles/tabs';
@import 'app/styles/container';
@import 'app/styles/carousel';

html, body {
  overflow-x: hidden;
}

.object-fit-cover {
  object-fit: cover;
}

.mat-divider {
  height: 1px;
  box-sizing: border-box;
  border: 2px solid #171714;
}
a.inline-hyper-link{
  color: #0F7D9C;
  text-decoration: none;
  font-weight: bold;
}
a.inline-hyper-link:visited {
  color: #666663;
}

.mobile-container {
  padding: 0 1rem
}

.components-margin{
  margin-top: 75px;
}

.sub-components-margin{
  margin-top: 30px;
}
.top-shadow{
  box-shadow: 0px -15px 10px -15px rgba(0, 0, 0, 0.5);
}
.bottom-shadow{
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.5);
}
.top-bottom-shadow{
  box-shadow: 0px -15px 10px -15px rgba(0, 0, 0, 0.5), 0px 15px 10px -15px rgba(0, 0, 0, 0.5);
}

.grecaptcha-badge {
  visibility: hidden;
}
