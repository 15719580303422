$font-family-Visby-Regular: "Visby Regular";
$font-family-Visby-DemiBold: "Visby DemiBold";
$font-family-Visby-Medium: "Visby Medium";
$font-family-Acceptance-Bold: "Acceptance Bold";
$font-family-Acceptance-DemiBold: "Acceptance DemiBold";
$font-family-Acceptance-ExtraBold: "Acceptance ExtraBold";
$font-family-Acceptance-Regular: "Acceptance Regular";
$font-family-Acceptance-Medium: "Acceptance Medium";

@font-face {
  font-family: "Visby Regular";
  src: url(../../assets/fonts/AWS-Acceptance-VisbySlabCF-Regular.woff2) format('woff2');
}

@font-face {
  font-family: "Visby DemiBold";
  src: url(../../assets/fonts/AWS-Acceptance-VisbySlabCF-DemiBold.woff2) format('woff2');
}

@font-face {
  font-family: "Visby Medium";
  src: url(../../assets/fonts/AWS-Acceptance-VisbySlabCF-Medium.woff2) format('woff2');
}

@font-face {
  font-family: "Acceptance Bold";
  src: url(../../assets/fonts/AWS-Acceptance-Bold.woff2) format('woff2');
}

@font-face {
  font-family: "Acceptance DemiBold";
  src: url(../../assets/fonts/AWS-Acceptance-DemiBold.woff2) format('woff2');
}

@font-face {
  font-family: "Acceptance ExtraBold";
  src: url(../../assets/fonts/AWS-Acceptance-ExtraBold.woff2) format('woff2');
}

@font-face {
  font-family: "Acceptance Regular";
  src: url(../../assets/fonts/AWS-Acceptance-Regular.woff2) format('woff2');
}

@font-face {
  font-family: "Acceptance Medium";
  src: url(../../assets/fonts/AWS-Acceptance-Medium.woff2) format('woff2');
}

