@import 'colors';

.card {
  padding: 1rem 1.5rem;
  border-radius: 11px;
  background-color: $white;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.12), 0 4px 25px 0 rgba(0,0,0,0.14);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  &:hover {
    box-shadow: 0 8px 54px 0 rgba(0,0,0,0.2), 0 4px 8px 0 rgba(0,0,0,0.23);
    &.card-expands {
      transform: scale(1.05);
    }
  }
  &.no-animation,
  &.no-animation:hover {
    box-shadow: none !important;
    transition: none !important;
  }
}

.card-description {
  max-height: calc(5 * 26px) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  display: "-webkit-box";
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
}

.card-description:last-child{
  display: block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.card-user-section {
  max-height: 50px !important;
}

.card-actions {
  width: auto;
  padding: 1rem;
  text-align: center;
}

.card-date {
  height: auto;
  color: $tertiary-indigo;
  font-family: $font-family-Visby-Regular;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  text-transform: capitalize;
}
