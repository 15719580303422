.swiper-pagination-bullet {
  width: 1.25rem;
  height: 1.25rem;
  opacity: 1;
  background-color: $secondary-brown;
  &.carousel-btn-active {
    background-color: $green;
  }
}

.swiper-pagination {
  top: 0 !important;
  bottom: 0 !important;
}

