@mixin font-weight($weight) {
  font-weight: $weight;
}

@mixin font-size($size) {
  font-size: $size;
}

@mixin letter-spacing($space) {
  letter-spacing: $space;
}

@mixin background-color($color) {
  background-color: $color;
}

.text-transform-none {
  text-transform: none;
}
