@import 'colors';
@import 'fontfamily';
@import 'typography';

h1, .h1 {
  line-height: 50px;
  font-family: $font-family-Acceptance-Bold;
  font-size: 53px;
  font-weight: bold;
}

h2, .h2 {
  line-height: 51px;
  font-family: $font-family-Acceptance-Bold;
  font-size: 48px;
  margin-bottom: .75rem;
  font-weight: bold;
}

h3, .h3 {
  line-height: 34px;
  font-family: $font-family-Acceptance-Medium;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: .75rem;
}

h4, .h4 {
  line-height: 24px;
  font-family: $font-family-Acceptance-Bold;
  font-size: 22px;
  margin-bottom: .75rem;
}

h5, .h5 {
  font-family: $font-family-Acceptance-Regular;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 12px;
  color: $indigo;
}

.secondary-heading {
  //border-bottom: 5px dotted $orange;
  padding: 0.7rem 0;
}

.user-name {
  color: $indigo;
  font-family: $font-family-Acceptance-Bold;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-transform: capitalize;
}

.user-details {
  color: $primary-black;
  font-family: $font-family-Acceptance-Bold;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-transform: capitalize;
}

.sitemap-title {
  color: $indigo;
  font-family: $font-family-Acceptance-Bold;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
}

.overline {
  color: $black;
  font-family: $font-family-Visby-DemiBold;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 22px;
}

.body-description {
  color: $primary-black;
  font-family: $font-family-Visby-Regular;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: .75rem;
}

.hero-image-disclaimer {
  color: $default-white;
  font-family: $font-family-Acceptance-ExtraBold;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}

.sky-blue-text {
  color: $sky-blue;
  text-transform: uppercase;
}

.body-description-heading {
  color: $yankees-blue;
  font-family: $font-family-Acceptance-ExtraBold;
  font-size: 19px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 24px;
}

.body-description-sub-heading {
  color: $yankees-blue;
  font-family: $font-family-Acceptance-Regular;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}

.article-fl {
  color: $primary-black;
  font-family: $font-family-Acceptance-Bold;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

.text-credit-yellow {
  color: $yellow;
}

.text-credit-indigo {
  color: $indigo;
}

.static-page{
  .body-description {
    padding: 4px 0 !important; 
  }
}
