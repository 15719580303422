@import 'typography';
@import 'colors';
@import 'fontfamily';


.font-light {
  @include font-weight(400);
}

.font-regular {
  @include font-weight(500);
}

.font-medium {
  @include font-weight(600);
}

.font-bold {
  @include font-weight(bold);
}

.font-size-small {
  @include font-size(12px);
}

.font-size-medium {
  @include font-size(16px);
}

.font-size-large {
  @include font-size(20px);
}

.letter-spacing-small {
  @include letter-spacing(0.4px);
}

.letter-spacing-medium {
  @include letter-spacing(0.6px);
}

.letter-spacing-large {
  @include letter-spacing(0.7px);
}
